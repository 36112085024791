import axios from 'axios'
import { Message,MessageBox} from 'element-ui'
// import store from '@/store'
// import {getToken} from '@/utils/auth'

// create an axios instance
const service = axios.create({
    baseURL: "https://www.amazegg.cn", // url = base url + request url
    // baseURL: "", // url = base url + request url
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
});

// request interceptor
// service.interceptors.request.use(
//     config => {
//         // do something before request is sent
//
//         if (store.getters.token) {
//             // let each request carry token
//             // ['X-Token'] is a custom headers key
//             // please modify it according to the actual situation
//             // config.headers['X-Token'] = getToken()
//             // config.headers['authentication'] = getToken()
//             config.headers['TensynToken'] = getToken()
//         }
//         return config
//     },
//     error => {
//         // do something with request error
//         console.log(error); // for debug
//         return Promise.reject(error)
//     }
// )

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    /**
     * 下面的注释为通过在response里，自定义code来标示请求状态
     * 当code返回如下情况则说明权限有问题，登出并返回到登录页
     * 如想通过 xmlhttprequest 来状态码标识 逻辑可写在下面error中
     * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
     */

    response => {
        const res = response.data
        // return res
        // if the custom code is not 20000, it is judged as an error.
        // if (res.code !== 20000) {
        if (res.code !== 0) {
            // console.log(res.msg);
            if (res.code === 4001) {
                // 4001:非法的token; //50012:其他客户端登录了;  50014:Token 过期了;
            } else if (res.code === 401) {
                /*
                // 请自行在引入 MessageBox
                // import { Message, MessageBox } from 'element-ui'
                MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    store.dispatch('user/resetToken').then(() => {
                        location.reload() // 为了重新实例化vue-router对象 避免bug
                    })
                })
                */
            } else if (res.code === 402) {
                Message({
                    message: res.msg || 'error',
                    type: 'error',
                    duration: 5 * 1000
                });
            } else {
                // Message({
                //     showClose: true,
                //     message: res.msg || 'error',
                //     type: 'error',
                //     duration: 5 * 1000
                // });
                MessageBox.alert(res.msg , {})
            }
            return Promise.reject(res || 'error')
        } else {
            return res
        }
    },

    error => {
        console.log(error) // for debug
        Message({
            message: error.msg,
            type: 'error',
            duration: 5 * 1000
        });
        return Promise.reject(error)
    }
);

export default service
