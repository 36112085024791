<template>
  <div class="home">
    <swiper ref="mySwiper" :options="swiperOptions" @slideChangeTransitionEnd="onSlideChangeEnd">
      <swiper-slide>
        <div class="bg item01" :style="{ 'backgroundSize': backgroundSize+'px '+backgroundSize/1920*1080+'px'  }">
          <div class="window" :style="{ 'backgroundSize': backgroundSize+'px '+backgroundSize/1920*1080+'px'   }">
            <div class="title animated fadeInLeft">
              <h2>广告行业资深匠人</h2>
              <h2 class="title-en">Advertising industry veteran</h2>
            </div>
            <div class="border">
              <div class="border-top"></div>
              <div class="icon-tip animated fadeIn delay02"><img src="../assets/home_icon_01.png" alt=""></div>
              <div class="quote animated fadeInRight">经多年多项目打磨，成就专业的广告人<br>
                高效的服务意识，创新的思维理念<br>
                让传播更具价值，让客户更具竞争力
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="bg item02" :style="{ 'backgroundSize': backgroundSize+'px '+backgroundSize/1920*1080+'px'  }">
          <div class="window" :style="{ 'backgroundSize': backgroundSize+'px '+backgroundSize/1920*1080+'px'   }">
            <div class="title animated fadeInLeft">
              <h2>数字时代互联网专家</h2>
              <h2 class="title-en" style="letter-spacing:-1px;">Internet expert in the digital age</h2>
            </div>
            <div class="border">
              <div class="border-top"></div>
              <div class="icon-tip animated fadeIn delay02"><img src="../assets/home_icon_02.png" alt=""></div>
              <div class="quote animated fadeInRight">资深的技术团队，多平台制作开发<br>
                完善的服务体系，创新的设计理念<br>
                从品牌、设计、技术全方位服务，持续<br>
                为客户创造价值
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="bg item03" :style="{ 'backgroundSize': backgroundSize+'px '+backgroundSize/1920*1080+'px'  }">
          <div class="window" :style="{ 'backgroundSize': backgroundSize+'px '+backgroundSize/1920*1080+'px'   }">
            <div class="title animated fadeInLeft">
              <h2>物联网技术 引领未来</h2>
              <h2 class="light-color">Cutting edge IOT tech</h2>
            </div>
            <div class="border">
              <div class="border-top"></div>
              <div class="icon-tip animated fadeIn delay02" style="background: #404986"><img src="../assets/home_icon_03.png" alt=""></div>
              <div class="quote animated fadeInRight">我们将缤纷的视觉感受，快捷的数字化信息，<br>
                真实的物理世界相连接，使用户身临其境，<br>
                感受奇妙有趣的互动体验，为客户提供智能<br>硬件一体化的物联网开发服务
              </div>
            </div>

          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'

export default {
  name: 'Home',
  // components: {
  //   Swiper,
  //   SwiperSlide
  // },
  // directives: {
  //   swiper: directive
  // },
  data() {
    return {
      backgroundSize: "",
      swiperOptions: {
        direction: 'horizontal',
        loop: true,
        autoplay: {
          delay: 4500,
          stopOnLastSlide: false,
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
          // type: 'fraction',
          // type : 'progressbar',
          //type : 'custom',
        },
        observeParents:true,//修改swiper的父元素时，自动初始化swiper
        observer:true //修改swiper自己或子元素时，自动初始化swiper
      }

    };
  },
  beforeMount() {
    this.resizeHandler();
    window.addEventListener('resize', this.resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  created: function () {
    // console.log(this.$route);
  },
  methods: {
    resizeHandler() {
      // console.log("resizeHandler");
      if (document.documentElement.clientWidth / (document.documentElement.clientHeight - 350) > 1920 / 1080) {
        this.backgroundSize = document.documentElement.clientWidth + 100;
      } else {
        this.backgroundSize = document.documentElement.clientHeight / 1080 * 1920 + 10;
      }
    },
    onSlideChangeEnd() {
      // console.log('Current Swiper instance object', this.swiper.el.style.opacity=0.5)
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
    // console.log('Current Swiper instance object', this.swiper)
    // this.swiper.slideTo(1, 1000, false)
  }
}
</script>

<style scoped lang="scss">
.swiper-slide .animated{
  display: none;
}
.swiper-slide.swiper-slide-active .animated{
  display: block;
}
.swiper-slide {
  //height: 740px;
  width: 100%;
  position: relative;
  min-height: 740px;
  height: calc(100vh - 350px);

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  .bg.item01 {
    background: url("../assets/bg_home01_bg.jpg") no-repeat center;
  }

  .bg.item02 {
    background: url("../assets/bg_home02_bg.jpg") no-repeat center;
  }

  .bg.item03 {
    background: url("../assets/bg_home03_bg.jpg") no-repeat center;
  }

  .window {
    position: absolute;
    width: 970px;
    height: 540px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    //margin-left: -485px;
    //margin-top: -270px;
    background: url("../assets/bg_home01.jpg") no-repeat center;
    box-shadow: 2px 2px 6px rgba(3, 10, 24, 0.1);
  }

  .bg.item01 .window {
    background: url("../assets/bg_home01.jpg") no-repeat center;
  }

  .bg.item02 .window {
    background: url("../assets/bg_home02.jpg") no-repeat center;
  }

  .bg.item03 .window {
    background: url("../assets/bg_home03.jpg") no-repeat center;
  }

  .window .border {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    border: #fff 2px solid;
    border-top-width: 28px;
    box-sizing: border-box;
    box-shadow: inset 2px 2px 6px rgba(3, 10, 24, 0.1);
  }

  .window .border-top {
    position: absolute;
    width: 75px;
    height: 28px;
    left: -2px;
    top: -28px;
    background: url("../assets/home_window_top.png") no-repeat;
  }

  .window .title {
    position: absolute;
    //width: 400px;
    //height: 95px;
    left: -20px;
    top: 18%;
    padding: 20px 24px 16px 45px;
    background: rgb(247, 176, 42, 0.8);
    font-size: 30px;
  }

  .window .title h2 {
    font-size: 1em;
    line-height: 1.2;
    margin: 0;
    font-weight: 400;
    color: #fff;
  }

  .title-en {
    font-size: 0.8em;
    opacity: 0.5;
  }

  .window .quote {
    position: absolute;
    //width: 425px;
    left: 0;
    top: 55%;
    //display: inline-block;
    background: rgb(0, 0, 0, 0.8) url("../assets/home_quote.png") no-repeat left top;
    padding: 22px 24px 22px 72px;
    font-size: 16px;
    color: #fff;
    line-height: 1.5;
  }

  .window .icon-tip {
    position: absolute;
    width: 91px;
    height: 91px;
    right: -45px;
    top: 40%;
    background: #b5b5b5;
  }

  .window .icon-tip img {
    margin-left: -2px;
    margin-top: -2px;
  }
}


@media only screen and (max-width: 991px) {

  .swiper-slide .window {
    width: 860px;
    //height: 540px;
    //margin-left: -430px;
    //margin-top: -270px;
  }
}

@media only screen and (max-width: 768px) {
  .swiper-slide {
    //height: 650px;

    .window {
      width: 80%;
      //height: 800px;
      //margin-left: -430px;
      //margin-top: -270px;
    }

    .window .title h2 {
      font-size: 26px;
    }

    .window .quote {
      top: 60%;
      padding: 22px 24px 22px 55px;
      font-size: 15px;
      background: rgb(0, 0, 0, 0.8) url("../assets/home_quote.png") no-repeat left top;
      background-size: 80px 40px;
    }

    .window .icon-tip {
      display: none;
    }
  }
}
</style>
