<template>
  <div id="app">
    <el-container>
      <header id="header" class="header">
        <div class="main-header">

          <router-link class="menu-main" to="/">
            <div class="header-logo"></div>
          </router-link>
          <div class="container">
            <div :class="[activeMobileMenu ? 'mobile-button active' : 'mobile-button']" @click="onMobileMenu"><span></span></div>
            <!-- /.mobile-button -->
            <nav class="main-nav animated fadeIn fast" :class="[activeMobileMenu ? 'main-nav active' : 'main-nav']">
              <ul id="menu-primary-menu" class="menu">
                <li class="menu-item menu-item-has-children animated fadeIn fast" :class="[$route.path==('/') ? 'menu-item menu-item-has-children active' : 'menu-item menu-item-has-children']">
                  <router-link class="menu-main" to="/">首页
                    <div class="lab-en">HOME</div>
                  </router-link>
                </li>
                <li class="menu-item menu-item-has-children animated fadeIn fast delay01"
                    :class="[$route.path.indexOf('/about')==0 ? 'menu-item menu-item-has-children active' : 'menu-item menu-item-has-children']">
                  <router-link class="menu-main" to="/about">公司介绍
                    <div class="lab-en">INTRODUCTION</div>
                  </router-link>
                <li class="menu-item menu-item-has-children animated fadeIn fast delay02"
                    :class="[$route.path.indexOf('/solution')==0 ? 'menu-item menu-item-has-children active' : 'menu-item menu-item-has-children']">
                  <router-link class="menu-main" to="/solution">解决方案
                    <div class="lab-en">SOLUTION</div>
                  </router-link>
                  <ul class="sub-menu">
                    <li class="menu-item" :class="[$route.path.indexOf('/solution/all')==0 ? 'menu-item current-item' : 'menu-item']">
                      <router-link to="/solution/all">全部</router-link>
                    </li>
                    <li class="menu-item" v-for="(item) in subMenuSolution" :key="item.id" :class="[$route.path.indexOf('/solution/'+item.id)==0 ? 'menu-item current-item' : 'menu-item']">
                      <router-link :to="'/solution/'+item.id">{{ item.name }}</router-link>
                    </li>
                  </ul>
                </li>
                <li class="menu-item menu-item-has-children animated fadeIn fast delay03"
                    :class="[$route.path.indexOf('/case')==0 ? 'menu-item menu-item-has-children active' : 'menu-item menu-item-has-children']">
                  <router-link class="menu-main" to="/case">精彩案例
                    <div class="lab-en">CASE</div>
                  </router-link>
                  <ul class="sub-menu">
                    <li class="menu-item" :class="[$route.path.indexOf('/case/all')==0 ? 'menu-item current-item' : 'menu-item']">
                      <router-link to="/case/all">全部</router-link>
                    </li>
                    <!--                    <li class="menu-item">-->
                    <!--                      <router-link to="/about">H5/小程序/WEB</router-link>-->
                    <!--                    </li>-->
                    <!--                    <li class="menu-item">-->
                    <!--                      <router-link to="/about">2D/3D动画</router-link>-->
                    <!--                    </li>-->
                    <!--                    <li class="menu-item">-->
                    <!--                      <router-link to="/about">平面设计/手绘/插画</router-link>-->
                    <!--                    </li>-->
                    <li class="menu-item" v-for="(item) in subMenuCase" :key="item.id" :class="[$route.path.indexOf('/case/'+item.id)==0 ? 'menu-item current-item' : 'menu-item']">
                      <router-link :to="'/case/'+item.id">{{ item.name }}</router-link>
                    </li>
                  </ul>
                </li>
                <li class="menu-item menu-item-has-children  animated fadeIn fast delay04"
                    :class="[$route.path.indexOf('/industry')==0 ? 'menu-item menu-item-has-children active' : 'menu-item menu-item-has-children']">
                  <router-link class="menu-main" to="/industry">行业精选
                    <div class="lab-en">INDUSTRY</div>
                  </router-link>
                  <ul class="sub-menu">
                    <li class="menu-item" :class="[$route.path.indexOf('/industry/all')==0 ? 'menu-item current-item' : 'menu-item']">
                      <router-link to="/industry/all">全部</router-link>
                    </li>
                    <li class="menu-item" v-for="(item) in subMenuIndustry" :key="item.id" :class="[$route.path.indexOf('/industry/'+item.id)==0 ? 'menu-item current-item' : 'menu-item']">
                      <router-link :to="'/industry/'+item.id">{{ item.name }}</router-link>
                    </li>
                  </ul>
                </li>
                <li class="menu-item menu-item-has-children animated fadeIn fast delay05"
                    :class="[$route.path.indexOf('/contact')==0 ? 'menu-item menu-item-has-children active' : 'menu-item menu-item-has-children']">
                  <router-link class="menu-main" to="/contact">联系我们
                    <div class="lab-en">CONTACT US</div>
                  </router-link>
                </li>
                <li class="menu-item menu-item-has-children animated fadeIn fast delay06"
                    :class="[$route.path.indexOf('/members')==0 ? 'menu-item menu-item-has-children active' : 'menu-item menu-item-has-children']">
                  <router-link class="menu-main" to="/members/list">会员专区
                    <div class="lab-en">MEMBERS</div>
                  </router-link>
                  <div class="sub-menu">
                    <div class="container">
                      <ul class="pull-right" v-if="hasToken">
                        <li class="menu-item" :class="[$route.path.indexOf('/members/list')==0 ? 'menu-item current-item' : 'menu-item']">
                          <router-link to="/members/list">收费文章</router-link>
                        </li>
                        <li class="menu-item" :class="[$route.path.indexOf('/members/order')==0 ? 'menu-item current-item' : 'menu-item']">
                          <router-link to="/members/order">购买记录</router-link>
                        </li>
                        <li class="menu-item" :class="[$route.path.indexOf('/logout')==0 ? 'menu-item current-item' : 'menu-item']">
                          <a href="javascript:;" @click="logout">退出</a>
                        </li>
                      </ul>
                      <ul class="pull-right" v-else>
                        <li class="menu-item" :class="[$route.path.indexOf('/members/list')==0 ? 'menu-item current-item' : 'menu-item']">
                          <router-link to="/members/list">收费文章</router-link>
                        </li>
                        <li class="menu-item" :class="[$route.path.indexOf('/members/login')==0 ? 'menu-item current-item' : 'menu-item']">
                          <router-link to="/members/login">登录</router-link>
                        </li>
                      </ul>

                    </div>
                  </div>
                </li>
              </ul>
            </nav>
            <!--            <ul class="sub-menu-line">-->
            <!--              <li class="menu-item" :class="[$route.path.indexOf('/solution/all')==0 ? 'menu-item current-item' : 'menu-item']">-->
            <!--                <router-link to="/solution/all">全部</router-link>-->
            <!--              </li>-->
            <!--              <li class="menu-item" v-for="(item) in subMenuSolution" :key="item.id" :class="[$route.path.indexOf('/solution/'+item.id)==0 ? 'menu-item current-item' : 'menu-item']">-->
            <!--                <router-link :to="'/solution/'+item.id">{{ item.name }}</router-link>-->
            <!--              </li>-->
            <!--            </ul>-->
          </div>
        </div>

      </header>

      <el-main>
        <!--        <transition :name="transitionName">-->
        <router-view/>
        <!--        </transition>-->
      </el-main>
      <!--   -->
      <el-footer height="auto">
        <div class="container">
          <el-backtop :right="12">TOP <i class="el-icon-top"></i></el-backtop>
          <el-row :gutter="10" class="footer">
            <el-col :xs="24" :sm="24" :md="7" :lg="7">
              <img src="./assets/logo_footer.png" width="240" height="94" class="logo-footer">
            </el-col>
            <el-col :xs="24" :sm="24" :md="17" :lg="17">
              <div class="footer-content">
                <i class="el-icon-location-information"></i> 北京市朝阳区朝阳北路103号青年公社102号楼528室 <span class="m-hide">|</span><br class="m-show"> <i class="el-icon-truck"></i> 100123<br>
                <i class="el-icon-phone-outline"></i> 010-65578228 / 13426166116 <span class="m-hide">|</span><br class="m-show"> <i class="el-icon-message"></i> info@amazegg.cn
                <a class="copy-btn" @click="copyEmail" data-clipboard-action="copy" data-clipboard-text="info@amazegg.cn"><i class="el-icon-document-copy"></i></a><br>
                <i class="el-icon-discover"></i> 作品著作权归奇蛋科技所有 © 2016-2021 版权所有 <br class="m-show"> <a href="https://beian.miit.gov.cn/" target="_blank">{{ ICP }}</a>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import Clipboard from 'clipboard';
import {ShareImpl, ShareSet} from './utils/share'   // 记得改为你的路径

export default {
  data() {
    return {
      activeIndex: '1',
      activeMobileMenu: false,
      subMenuSolution: [],
      subMenuCase: [],
      subMenuIndustry: [],
      transitionName: 'slide-left',
      ICP: '京ICP备16007731号-1',
      hasToken: false
    };
  },
  watch: {
    '$store.state.token'(newVal, oldVal) {
      //对数据执行操作
      // console.log(newVal,oldVal)
      this.hasToken = (newVal != null);
    },

    '$route'(to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'

      this.activeMobileMenu = false;
      //console.log("APP watch location", location.href);
      //console.log(to, from);

      // var _hmt = _hmt || [];
      window._hmt.push(['_trackEvent', 'page', to.path, 1]);

      var titleName = {
        'home': "公司介绍",
        'solution': "解决方案",
        'case': "精彩案例",
        'industry': "行业精选",
        'contact': "联系我们",
        'login': "会员登录",
        'members': "会员专区",
      }
      let shareTitle = '奇蛋科技';
      for (let key in titleName) {
        //console.log(key + '---' + titleName[key])
        if (to.path.indexOf(key) === 1) {
          shareTitle = '奇蛋科技 - ' + titleName[key];
        }
      }

      let shareURL = location.href.indexOf("h5designer.com") > 0 ? "https://h5designer.com/" : "https://www.amazegg.cn/";
      ShareSet({
        shareTitle: shareTitle,
        shareDesc: '互联网营销技术解决方案专家',
        shareUrl: location.href,
        shareImg: shareURL + 'share_2.jpg',
      });
    }
  },
  beforeCreate() {
    if (window.localStorage.getItem("token")) {
      this.$store.commit('updateToken', window.localStorage.getItem("token"));
    }
  },
  created: function () {
    // console.log(this.$route);
    if (location.href.indexOf("h5designer.com") >= 0) {
      this.ICP = "豫ICP备14019426号-2";
    } else {
      this.ICP = "京ICP备16007731号-1";
    }

    // let shareURL = location.href.indexOf("h5designer.com") > 0 ? "https://h5designer.com/" : "https://www.amazegg.cn/";
    // // window.console.log(url + 'favicon.png');
    // ShareImpl({
    //   shareTitle: '奇蛋科技',
    //   shareDesc: '互联网营销技术解决方案专家',
    //   shareUrl: shareURL,
    //   shareImg: shareURL + 'share_2.jpg',
    // });

    this.initSubMenu();
    this.initUser();
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    onMobileMenu() {
      this.activeMobileMenu = !this.activeMobileMenu;
    },
    initSubMenu() {
      let that = this;
      this.$http.post(
          '/index/article/get_children_category',
          {})
          .then(function (response) {
            // console.log(response);
            for (var i in response.data) {
              let curItem = response.data[i];
              if (curItem.pid == 2) {
                that.subMenuSolution.push({
                  pid: curItem.pid,
                  id: curItem.id,
                  name: curItem.name
                })
                // that.subMenuSolution = response.data;
              } else if (curItem.pid == 3) {
                // that.subMenuCase = response.data;
                that.subMenuCase.push({
                  pid: curItem.pid,
                  id: curItem.id,
                  name: curItem.name
                })
              } else if (curItem.pid == 1) {
                // that.subMenuIndustry = response.data;
                that.subMenuIndustry.push({
                  pid: curItem.pid,
                  id: curItem.id,
                  name: curItem.name
                })
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    initUser() {
      let that = this;
      //  window.localStorage.setItem("token", token);
      let token = window.localStorage.getItem("token");

      if (token) {
        this.hasToken = true;
        this.$http.post(
            '/index/auth/get_user_info',
            {},
            {
              headers: {
                'token': token
              }
            })
            .then(function (res) {
              // console.log(res);
              if (res.code === 0) {
                that.$store.commit('setUserInfo', res.data);
                that.$store.commit('updateToken', token);
              } else {
                that.hasToken = false;
                that.$store.commit('updateToken', null);
                window.localStorage.removeItem("token");
              }
            })
            .catch(function (error) {
              console.log(error);
              that.hasToken = false;
              that.$store.commit('updateToken', null);
              window.localStorage.removeItem("token");
            });
      }
    },
    logout() {
      let that = this;
      this.hasToken = false;
      that.$store.commit('updateToken', null);
      window.localStorage.removeItem("token");
      // that.$router.push(`/members/login`);
      location.href = './#/members/login';
    },
    copyEmail() {
      // document.getElementById("footerEmail").select();
      // document.execCommand("Copy"); // 执行浏览器复制命令
      // //alert("已复制好，可贴粘。");
      // this.$message({message: '复制成功', type: 'success'});

      // var clipboard = new Clipboard('.copy-btn');
      // clipboard.on('success', e => {
      //   console.log('复制成功', e)
      //   // this.toast('copy success');
      //   // 释放内存
      //   clipboard.destroy();
      // })
      // clipboard.on('error', e => {
      //   // 不支持复制
      //   console.log('该浏览器不支持自动复制', e)
      //   // 释放内存
      //   clipboard.destroy()
      // })

      var clipboard = new Clipboard('.copy-btn');
      clipboard.on('success', e => {
        console.log('复制成功', e)
        this.$message({message: '复制成功', type: 'success'});
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        console.log('该浏览器不支持复制', e)
        clipboard.destroy()
      })

    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
}


#container {
  width: 100%;
  height: 300px;
  background: #FFBBBB;
}

#footerEmail {
  display: none;
}
</style>
