import axios from 'axios';
import wx from 'weixin-js-sdk';

const ShareImpl = function (option) {
    //option是分享的配置内容*/
    const url = window.location.href.split("#")[0];
    // window.console.log(url, JSON.stringify(option));
    let data = new FormData();
    data.append('url', url);
    axios.post('//old.mazegg.cn/apps/web/index.php?s=WxApi/getSignPackage', data).then((response) => {
        // console.log('shareCfg', response.data);
        let res = response.data.data;
        wx.config({
            debug: false,
            appId: res.appId,
            timestamp: res.timestamp,
            nonceStr: res.nonceStr,
            signature: res.signature,
            jsApiList: [
                'checkJsApi', 'onMenuShareTimeline', 'onMenuShareAppMessage',
                'updateAppMessageShareData', 'updateTimelineShareData'
            ]
        });
    }).catch(error => {
        window.console.log(error);
    });
    wx.ready(function () {
        // wx.updateAppMessageShareData({
        //     title: option.shareTitle,
        //     desc: option.shareDesc,
        //     link: option.shareUrl,
        //     imgUrl: option.shareImg
        // });
        // wx.updateTimelineShareData({
        //     title: option.shareTitle + ' ' + option.shareDesc,
        //     link: option.shareUrl,
        //     imgUrl: option.shareImg
        // });
        ShareSet(option);
    })
}
const ShareSet = function (option) {
    wx.updateAppMessageShareData({
        title: option.shareTitle,
        desc: option.shareDesc,
        link: option.shareUrl,
        imgUrl: option.shareImg
    });
    wx.updateTimelineShareData({
        title: option.shareTitle + ' ' + option.shareDesc,
        link: option.shareUrl,
        imgUrl: option.shareImg
    });
}
export {ShareImpl, ShareSet}

// 然后在main.js中引用该配置：
// let shareURL = location.href.indexOf("h5designer.com") > 0 ? "https://h5designer.com/" : "https://www.amazegg.cn/";
// // window.console.log(url + 'favicon.png');
// ShareImpl({
//     shareTitle: '奇蛋科技',
//     shareDesc: '互联网营销技术解决方案专家！',
//     shareUrl: shareURL,
//     shareImg: shareURL + 'share.png',
// });
