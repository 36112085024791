import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    signature: null,
    token: null,
    userInfo: {}
  },
  mutations: {
    updateSignature: (state, val) => {
      state.signature = val
    },
    updateToken: (state, val) => {
      state.token = val
    },
    setUserInfo: (state, val) => {
      state.userInfo = val
    }
  },
  getters: {
    signature(state) {
      return state.signature
    },
    token(state) {
      return state.token
    },
    baseURL(state) {
      return state.baseURL
    },
    userInfo(state) {
      return state.userInfo
    }
  },
  actions: {},
  modules: {}
})
