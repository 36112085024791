import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/HomeDev.vue'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
    },
    {
        path: '/solution',
        name: 'Solution',
        redirect: '/solution/all'
    },
    {
        path: '/solution/:category',
        name: 'Solution',
        component: () => import('../views/Solution.vue')
    },
    {
        path: '/solution/:category/:id',
        name: 'Solution',
        component: () => import('../views/Page.vue')
    },
    {
        path: '/case',
        name: 'Case', redirect: '/case/all'
    },
    {
        path: '/case/:category',
        name: 'CasePage',
        component: () => import('../views/Case.vue')
    },
    {
        path: '/case/:category/:id',
        name: 'CasePage',
        component: () => import('../views/Page.vue')
    },
    {
        path: '/industry',
        name: 'Industry',
        redirect: '/industry/all'
    },
    {
        path: '/industry/:category',
        name: 'Industry',
        component: () => import('../views/Industry.vue')
    },
    {
        path: '/industry/:category/:id',
        name: 'Industry',
        component: () => import('../views/Page.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue')
    },
    {
        path: '/members/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/members/list',
        name: 'Members',
        component: () => import('../views/Members.vue')
    },
    {
        path: '/members/order',
        name: 'order',
        component: () => import('../views/Order.vue')
    },
    {
        path: '/members/:id',
        name: 'members',
        component: () => import('../views/PageAuth.vue')
    }
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
